.app_header {
  max-height: 300px;
  font-size: 10px;
  padding: 10px;
  background-color: white;
  color: #444;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  font-size: 35px;
  font-weight: 700;
}

.button_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  margin: 15px 0 0;
}

.button {
  height: 30px;
  border-radius: 8px;
  background-color: #444;
  color: white;
  font-weight: bold;
  font-size: 12px;
  outline: none;
  border: none;
  text-transform: uppercase;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
  margin: 0 5px;
}

.button:hover {
  background-color: #888;
  cursor: pointer;
  transform: translateY(-0.5px);
}

.button.focus,
.button:focus {
  outline: 0;
  box-shadow: none !important;
}

.button_cancel {
  background-color: #ddd;
  color: #444;
  border: #aaa solid 1px;
}

.button_cancel:hover {
  background-color: #fff;
  outline: none;
}

.button_disabled {
  opacity: 0.6;
}

.button_disabled:hover {
  cursor: default;
}

.dialog_box {
  border-radius: 5px;
  width: 500px;
}

.input_row {
  margin: 10px 0px;
}

.hyperlink {
  border: none;
  text-decoration: underline;
  color: blue;
  font-size: 12px;
}

.hyperlink:hover {
  cursor: pointer;
}

.json_box {
  white-space: pre-wrap;
  padding: 10px;
  background: whitesmoke;
  max-height: 200px;
  overflow-y: scroll;
  border: 1px solid #777;
}

.input_error_text_container {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

@media screen and (max-width: 900px) {
  .title {
    font-size: 25px;
  }
}

@media screen and (max-width: 600px) {
  .dialog_box {
    width: 400px;
  }
}

@media screen and (max-width: 500px) {
  .dialog_box {
    width: 350px;
  }
}

@media screen and (max-width: 450px) {
  .dialog_box {
    width: 300px;
  }
}

@media screen and (max-width: 400px) {
  .dialog_box {
    width: 250px;
  }
}

@media screen and (max-width: 350px) {
  .dialog_box {
    width: 200px;
  }
}

@media screen and (max-width: 300px) {
  .dialog_box {
    width: 200px;
  }
}
