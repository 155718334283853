.toast {
  background-color: #444;
  color: white;
  padding: 10px;
  width: auto;
  max-width: 175px;
  position: fixed;
  top: 100px;
  left: 50%;
  border-radius: 10px;
  text-align: center;
  z-index: 100;
  transform: translate(-50%, 0);
  box-shadow: 0px 3px 19px 0px rgba(0, 0, 0, 0.3);
}

.toast_success {
  background-color: #5fba55;
}

.toast_error {
  background-color: #ce2929;
}
