.container {
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap-reverse;
}

.basic_info_container {
  width: 500px;
  flex-grow: 5;
}

.basic_info_header {
  font-weight: bold;
  color: #444;
  margin: 15px 0;
}

.basic_info_data {
  display: flex;
  flex-wrap: nowrap;
  margin: 5px 0;
}

.basic_info_row_label {
  color: #444;
  flex: 2;
  margin-right: 5px;
}

.basic_info_row_value {
  color: #333;
  font-weight: 500;
  flex: 8;
}

.graphic_container {
  height: 200px;
  margin: 0px 0px 30px;
  padding-bottom: 30px;
  flex-grow: 6;
  text-align: center;
}

.bulb {
  border-radius: 15px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
}

.bulb:hover {
  cursor: pointer;
}

.bulb_off {
  color: #fff;
  background-color: #333;
  /* border: none; */
  border: solid #333;
}

.bulb_on {
  color: #ffe16b;
  background-color: white;
  border: solid #444;
}

.bulb_offline {
  color: #fff;
  background-color: #ddd;
  border: none;
}

.bulb_offline:hover {
  cursor: default;
}

.circle {
  display: inline-block;
  border-radius: 100px;
  height: 10px;
  width: 10px;
  margin-right: 5px;
}

.green_circle {
  composes: circle;
  background-color: green;
  animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
}

.red_circle {
  composes: circle;
  background-color: red;
}

@keyframes scaleIn {
  from {
    transform: scale(0.5, 0.5);
    opacity: 1;
  }
  to {
    transform: scale(2, 2);
    opacity: 0;
  }
}

/* @media screen and (max-width: 940px) {
  .graphic_container {
    margin: 0px 0px 20px;
  }
} */
