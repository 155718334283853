html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label {
  display: block;
  padding-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
}

input {
  width: 100%;
  height: 32px;
  padding: 0 10px;
  border-radius: 4px;
  border-style: solid;
  border-color: #eee;
  display: inline-block;
  box-sizing: border-box;
}

footer {
  flex-shrink: 0;
  background: #222;
  color: white;
  padding: 0px;
  text-align: center;
}

#root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.App {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
